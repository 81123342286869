<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-select
          :items="xeroLinks"
          label="For"
          class="pt-0 mt-0"
          item-text="xeroCustomerName"
          item-value="xeroCustomerId"
          v-model="invoice.contactId"
          @change="changeContact"
        >
          <template v-slot:append-outer>
            <v-btn icon :href="''">
              <v-icon> mdi-open-in-new </v-icon>
            </v-btn>
          </template>
        </v-select>
        <v-text-field
          class="mt-3 mb-0"
          hide-details
          single-line
          label="Date"
          filled
          v-model="invoiceDate"
          dense
          :disabled="!canEdit"
        ></v-text-field>
        <v-text-field
          v-model="invoiceDueDate"
          class="mt-3"
          single-line
          hide-details
          label="Due Date"
          filled
          dense
          :disabled="!canEdit"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <span class="h5">
          Status: <span class="warning--text">DRAFT</span>
        </span>
        <v-text-field
          class="mt-3"
          hide-details
          single-line
          label="Reference"
          filled
          v-model="invoice.reference"
          :disabled="!canEdit"
          dense
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th>Description</th>
              <th>Qty</th>
              <th>Unit Cost</th>
              <th>Account Code</th>
              <th>Tax Rate</th>
              <th>Line Total</th>
              <th v-if="canEdit">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(lineItem, index) in invoice.lineItems" :key="index">
              <td>
                {{ lineItem.description }}
              </td>
              <td>
                {{ lineItem.quantity }}
              </td>
              <td>£{{ lineItem.unitCost.toFixed(2) }}</td>
              <td>
                {{ lineItem.accountCode }} - {{ lineItem.accountCodeName }}
              </td>
              <td>{{ lineItem.taxRate }}%</td>
              <td>£{{ (lineItem.unitCost * lineItem.quantity).toFixed(2) }}</td>
              <td></td>
            </tr>
            <tr v-if="canEdit">
              <td>
                <v-text-field dense hide-details single-line label="Name">
                </v-text-field>
              </td>
              <td>
                <v-text-field
                  dense
                  hide-details
                  single-line
                  label="Description"
                >
                </v-text-field>
              </td>
              <td>
                <v-text-field dense hide-details single-line label="Quantity">
                </v-text-field>
              </td>
              <td>
                <v-text-field dense hide-details single-line label="Unit Cost">
                </v-text-field>
              </td>
              <td>
                <v-select
                  dense
                  hide-details
                  single-line
                  label="Account Code"
                  :items="accounts"
                  return-object
                  :item-value="'code'"
                >
                  <template v-slot:selection="{ item }">
                    <span>{{ item.code + " - " + item.name }}</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <span>{{ item.code + " - " + item.name }}</span>
                  </template>
                </v-select>
              </td>
              <td>
                <v-text-field dense hide-details single-line label="Tax Rate">
                </v-text-field>
              </td>
              <td></td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" icon color="primary">
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span> Add Line Item </span>
                </v-tooltip>
                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-on="on" v-bind="attrs" icon color="warning">
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </template>
                  <span> Clear </span>
                </v-tooltip> -->
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    invoice: {
      type: Object,
      default: () => {},
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    companyId: {
      type: String,
      default: "",
    },
    showFormattedDates: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    invoiceDate() {
      if (this.showFormattedDates) {
        return this.invoice.date.toLocaleDateString();
      } else {
        return this.invoice.date;
      }
    },
    invoiceDueDate() {
      if (this.showFormattedDates) {
        return this.invoice.dueDate.toLocaleDateString();
      } else {
        return this.invoice.dueDate;
      }
    },
  },
  watch: {
    invoice: {
      deep: true,
      handler() {
        this.$emit("update:invoice", this.invoice);
      },
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.canEdit) {
        this.getAccounts();
      }
      this.getXeroLinks();
    },
    async changeContact() {
      //get the contact
      var xeroLinkItem = this.xeroLinks.find(
        (x) => x.id === this.invoice.contactId
      );

      this.invoice.tenantId = xeroLinkItem.xeroTenantId;
    },
    async getXeroLinks() {
      var links = await this.$courier.Companies.withId(
        this.companyId
      ).XeroLinks.getData(1, 999, "");

      this.xeroLinks = links.payload;

      if (this.xeroLinks.length === 1) {
        //now get the contact object for the invoice
        var xeroLinkItem = this.xeroLinks[0];

        this.invoice.tenantId = xeroLinkItem.xeroTenantId;
        this.invoice.contactId = xeroLinkItem.xeroCustomerId;
      }
    },
    async getAccounts() {
      this.accounts = [];

      var profiles = await this.$store.getters.getXeroProfiles;

      for (const tenantId of profiles.map((x) => x.tenantId)) {
        this.$courier.XeroAccountExternal.getData(1, 9999, "", null, null, [
          { key: "tenantId", value: tenantId },
          { key: "accountTypeFilter", value: "REVENUE" },
        ]).then((x) => {
          x.payload.forEach((s) => {
            this.accounts.push(s);
          });
        });
      }
    },
  },
  data() {
    return {
      xeroLinks: [],
      accounts: [],
      accountCodes: [],
    };
  },
};
</script>

<style>
</style>