<template>
    <v-container fluid>
        <v-row>
            <v-col cols="8">
                <v-card>
                    <v-toolbar>
                        <v-toolbar-title v-if="isLoading">
                            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
                        </v-toolbar-title>
                        <template v-else>
                            <v-toolbar-title>
                                <v-icon class="mr-2">mdi-rocket</v-icon>
                                <span class="mr-2">Job <span v-if="job.poNumber">#<span class="info--text">{{
                                    job.poNumber
                                }}</span></span></span>
                                <span class="mr-2">{{ job.title }}</span>
                            </v-toolbar-title>
                            <v-spacer>
                            </v-spacer>
                            <v-toolbar-title>
                                <v-chip :color="statusColour" class="mr-2">
                                    <span class="ml-4 mr-4">{{ job.status }}</span></v-chip>
                                <v-chip color="success" v-if="job && job.invoiceSent">
                                    Invoice has been sent
                                </v-chip>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-title>
                                <small>
                                    Added by {{ job.addedBy }} @ {{ new Date(job.dateAdded).toLocaleString() }}
                                </small>
                            </v-toolbar-title>
                        </template>
                    </v-toolbar>
                    <v-card-text v-if="isLoading">
                        <v-skeleton-loader type="text@5"></v-skeleton-loader>
                    </v-card-text>
                    <v-card-text v-else v-html="job.description">
                    </v-card-text>
                    <v-toolbar>
                        <v-tabs v-model="tab">
                            <v-tabs-slider></v-tabs-slider>
                            <v-tab value="notes">
                                <v-badge :content="noteCount" :value="noteCount > 0">
                                    <v-icon class="mr-2">mdi-pencil</v-icon>
                                    Notes
                                </v-badge>
                            </v-tab>
                            <v-tab>
                                <v-badge :content="lineItemCount" :value="lineItemCount > 0">
                                    <v-icon class="mr-2">mdi-playlist-star</v-icon>
                                    Line items</v-badge>
                            </v-tab>
                        </v-tabs>
                    </v-toolbar>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card-text>
                                <v-textarea @keydown.enter="changeNote" rows="3" label="Add notes"
                                    v-model="currentNote.note">
                                </v-textarea>
                                <v-btn @click="changeNote">
                                    <v-icon class="mr-2">
                                        {{ (isEditingNote ? 'mdi-pencil' : 'mdi-plus') }}
                                    </v-icon>
                                    {{ addNoteText }} Note
                                </v-btn>
                                <v-btn text @click="currentNote = {}" v-show="!isEditingNote && currentNote.note">
                                    Cancel
                                </v-btn>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-text v-for="note in job.notes" :key="note.id">
                                <h5>
                                    <v-icon class="mr-2">mdi-note-text-outline</v-icon>{{ note.note }}
                                </h5>
                                <v-chip small class="white--text">
                                    Added By {{ note.addedBy }} @ {{ new Date(note.dateAdded).toLocaleString() }}
                                </v-chip>
                                <v-btn @click="editNote(note)" small color="warning" class="ml-4">
                                    <v-icon class="mr-2">mdi-pencil</v-icon>Edit
                                </v-btn>
                                <v-btn small color="danger" class="ml-2" @click="showDeleteNote(note)">
                                    <v-icon class="mr-2">mdi-delete-outline</v-icon>
                                    Delete
                                </v-btn>
                                <br>
                            </v-card-text>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card-text>
                                <v-form>
                                    <v-container class="pt-0 pb-0">
                                        <v-row>
                                            <v-col cols="4" class="pt-0 pb-0">
                                                <v-text-field v-model="currentLineItem.description" hide-details
                                                    prepend-inner-icon="mdi-card-text-outline" dense outlined
                                                    label="Description"></v-text-field>
                                            </v-col>
                                            <v-col cols="3" class="pt-0 pb-0">
                                                <v-text-field v-model="currentLineItem.quantity" hide-details dense
                                                    prepend-inner-icon="mdi-counter" outlined label="Quantity"
                                                    type="number"></v-text-field>
                                            </v-col>
                                            <v-col cols="3" class="pt-0 pb-0">
                                                <v-text-field v-model="currentLineItem.price" hide-details
                                                    prepend-inner-icon="mdi-currency-gbp" dense outlined label="Price"
                                                    @keydown.enter="addLineItem"></v-text-field>
                                            </v-col>
                                            <v-col cols="2" class="pt-0 pb-0" @click="addLineItem">
                                                <v-btn><v-icon class="mr-2">mdi-plus</v-icon>{{
                                                    addLineItemText
                                                }}</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th>
                                                Description
                                            </th>
                                            <th>
                                                Quantity
                                            </th>
                                            <th>
                                                Price
                                            </th>
                                            <th>
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="lineItem in job.lineItems" :key="lineItem.id">
                                            <td>
                                                {{ lineItem.description }}
                                            </td>
                                            <td>
                                                {{ lineItem.quantity }}
                                            </td>
                                            <td>
                                                £{{ lineItem.price }}
                                            </td>
                                            <td>
                                                <v-btn small class="mr-2" color="warning"
                                                    @click="editLineItem(lineItem)">
                                                    <v-icon class="mr-2">mdi-pencil</v-icon>
                                                    Edit
                                                </v-btn>
                                                <v-btn small color="danger" @click="showDeleteLineItem(lineItem)">
                                                    <v-icon class="mr-2">mdi-delete-outline</v-icon>
                                                    Delete
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-toolbar>
                        <v-toolbar-title>
                            <v-icon class="mr-2">
                                mdi-text-box-outline
                            </v-icon>
                            Details
                        </v-toolbar-title>
                        <v-spacer>
                        </v-spacer>
                        <v-toolbar-title>
                            <v-chip color="info">
                                {{ job.division }}
                            </v-chip>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-select label="Status" v-model="newStatus" outlined
                            :items="['Open', 'In Progress', 'On Hold', 'Complete']">
                        </v-select>
                        <template v-if="newStatus != job.status">
                            <v-btn block color="primary" class="mb-4" @click="saveStatus" :loading="isLoadingStatus">
                                <v-icon class="mr-2">
                                    mdi-save
                                </v-icon>
                                Save Status
                            </v-btn>
                            <v-divider></v-divider>
                        </template>
                        <v-btn block :loading="isLoadingInvoiceSent" @click="setInvoiceSent"
                            :color="(job.invoiceSent ? 'success' : 'warning')" class="mb-4">
                            <v-icon class="mr-2">
                                {{ (job.invoiceSent ? 'mdi-check' : 'mdi-close') }}
                            </v-icon>
                            {{ (job.invoiceSent ? 'Invoice Has been sent' : 'No invoice has been sent') }}
                        </v-btn>
                        <v-btn v-if="!job.invoiceSent && companyXeroLinks.length === 1" class="mb-4" block
                            color="success" @click="showSendToXero(companyXeroLinks[0])" :loading="isLoadingSendToXero">
                            <v-icon class="mr-2">mdi-send</v-icon>
                            Send to Xero
                        </v-btn>
                        <v-menu v-if="!job.invoiceSent && companyXeroLinks.length > 1">
                            <template #activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" class="mb-4" block color="success"
                                    :loading="isLoadingSendToXero">
                                    <v-icon class="mr-2">mdi-send</v-icon>
                                    Send to Xero
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title>
                                        There are multiple links, please select:
                                    </v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item @click="showSendToXero(link)" v-for="(link, index) in companyXeroLinks"
                                    :key="index" :value="index">
                                    <v-list-item-title>
                                        <v-icon class="mr-2">mdi-office-building-outline</v-icon>
                                        {{ link.xeroCustomerName }}

                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-divider></v-divider>
                    </v-card-text>
                    <v-list>
                        <v-list-item v-if="job.coffeeCupContact && job.coffeeCupUserId">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn block>
                                        <v-icon class="mr-2">mdi-account-star-outline</v-icon>Project Manager:
                                        {{ job.coffeeCupContact.firstName + ' ' + job.coffeeCupContact.lastName }}
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="job.contactId && job.contact">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn block :to="{ name: 'Contact View', params: { contactId: job.contactId } }">
                                        <v-icon class="mr-2">mdi-account-star-outline</v-icon>Contact:
                                        {{ job.contact.firstName + ' ' + job.contact.lastName }}
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="job.parentCompanyId && job.company">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-btn block
                                        :to="{ name: 'Company Dashboard', params: { companyId: job.parentCompanyId } }">
                                        <v-icon class="mr-2">mdi-office-building-outline</v-icon>
                                        Company {{ job.company.name }}
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    Reference Number
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    <h4>
                                        {{ job.referenceNumber }}
                                    </h4>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="startDate != ''">
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    From
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    <h4>
                                        {{ startDate }}
                                    </h4>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="endDate != ''">
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    To
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    <h4>
                                        {{ endDate }}
                                    </h4>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-card-text>
                        <h3>
                            Price: <span class="success--text">£{{ totalCost }}</span>
                        </h3>
                    </v-card-text>
                    <v-toolbar>
                        <v-toolbar-title>
                            <v-icon class="mr-2">
                                mdi-cog
                            </v-icon>
                            Options
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-btn color="warning" class="mr-2" block :to="{
                                    name: 'Add Job',
                                    params: {
                                        companyId: job.parentCompanyId,
                                        jobId: job.id
                                    }
                                }">
                                    <v-icon class="mr-2">mdi-pencil</v-icon>
                                    Edit
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn color="danger" block @click="showDeleteJob">
                                    <v-icon class="mr-2">
                                        mdi-delete-outline
                                    </v-icon>
                                    Delete
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <trev-confirm-delete-dialog :maxWidth="'60vh'" @delete="confirmDeleteNote" @cancel="cancelDeleteNote"
            :noItemToDelete="true" :show.sync="isShowingDelete"></trev-confirm-delete-dialog>
        <trev-confirm-delete-dialog :show.sync="isShowingDeleteLineItem" :noItemToDelete="true" :maxWidth="'60vh'"
            @delete="confirmDeleteLineItem" @cancel="cancelDeleteLineItem"
            :itemToDelete="deleteLineItem.description"></trev-confirm-delete-dialog>
        <trev-confirm-delete-dialog :itemToDelete="'this job'" @delete="confirmDeleteJob" @cancel="cancelDeleteJob"
            :show.sync="isShowingDeleteJob">
        </trev-confirm-delete-dialog>
        <v-dialog v-model="isShowingSendToXero" max-width="100vh">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        Invoice
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="sendInvoiceToXero" :disabled="hasSuccesfullySentInvoice">
                        <v-icon class="mr-2">
                            mdi-send
                        </v-icon>
                        Send to Xero
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row class="my-4" v-if="isLoadingXeroInvoice && !hasSuccesfullySentInvoice">
                        <v-col class="text-center my-10">
                            <v-progress-circular class="py-10" indeterminate></v-progress-circular>
                        </v-col>
                    </v-row>
                    <trev-invoice-view ref="invoiceView" :canEdit="false" :companyId="job.parentCompanyId"
                        :invoice.sync="currentInvoice" :showFormattedDates="true"
                        v-else-if="!isLoadingXeroInvoice && !hasSuccesfullySentInvoice"></trev-invoice-view>
                    <v-row class="my-4" v-else-if="hasSuccesfullySentInvoice">
                        <v-col class="text-center my-10">
                            {{ invoiceMessage }}
                            <v-icon class="success--text" large> mdi-check </v-icon>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
 
<script>
import trevConfirmDeleteDialog from '../../../../components/base/dialog/trev-confirm-delete-dialog.vue';
import TrevInvoiceView from '../../../../components/invoices/trev-invoice-view.vue';
import * as genUtils from '../../../../utilities/GeneralUtilities'

export default {
    components: { trevConfirmDeleteDialog, TrevInvoiceView },
    created() {

        this.getJob().then(() => {
            setTimeout(() => {
                this.isLoading = false;
            }, 300);
        });
    },
    computed: {
        totalCost() {
            if (this.job && this.job.lineItems) {
                var total = this.job.lineItems.reduce((acc, item) => acc + (parseFloat(item.quantity) * parseFloat(item.price)), 0);


                console.log(total);
                return total;
            } else {
                return 0;
            }
        },
        statusColour() {
            if (this.job.status) {
                if (this.job.status == 'Open') {
                    return 'danger';
                } else if (this.job.status == 'In Progress') {
                    return 'purple';
                } else if (this.job.status == 'On Hold') {
                    return 'blue-gray';
                } else if (this.job.status == 'Complete') {
                    return 'success'
                }
            }

            return '';
        },
        noteCount() {
            if (this.job && this.job.notes && this.job.notes.length) {
                return this.job.notes.length;
            }

            return 0;
        },
        lineItemCount() {
            if (this.job && this.job.lineItems && this.job.lineItems.length) {
                return this.job.lineItems.length;
            }

            return 0;
        },
        addLineItemText() {
            if (this.isEditingLineItem) {
                return 'Save';
            } else {
                return 'Add';
            }
        },
        addNoteText() {
            if (this.isEditingNote) {
                return 'Save';
            } else {
                return 'Add';
            }
        },
        startDate() {
            if (this.job.startDate && genUtils.isValidDate(new Date(this.job.startDate))) {
                return new Date(this.job.startDate).toLocaleDateString('en-GB');
            }

            return '';
        },
        endDate() {
            if (this.job.endDate && genUtils.isValidDate(new Date(this.job.endDate))) {
                return new Date(this.job.endDate).toLocaleDateString('en-GB');
            }

            return '';
        }
    },
    methods: {
        showDeleteJob(job){
            this.jobToDelete = job;
            this.isShowingDeleteJob = true;
        },
        cancelDeleteJob() {
            this.jobToDelete = {};
            this.isShowingDeleteJob = false;
        },
        confirmDeleteJob() {
            this.$courier.Jobs.removeById(this.job.id).then(() => {
                this.$router.push({
                    name: 'Company Dashboard',
                    params: {
                        companyId: this.job.parentCompanyId
                    }
                });
            });
        },
        setInvoiceSent() {
            this.isLoadingInvoiceSent = true;
            this.$courier.Jobs.getById(this.job.id).then(job => {
                job.invoiceSent = !job.invoiceSent;
                //update it

                this.$courier.Jobs.updateById(job.id, job).then(newJob => {
                    this.job.invoiceSent = job.invoiceSent;
                    this.isLoadingInvoiceSent = false;
                })
            })
        },
        saveStatus() {
            this.isLoadingStatus = true;
            this.$courier.Jobs.getById(this.job.id).then(job => {
                job.status = this.newStatus;
                this.$courier.Jobs.updateById(this.job.id, job).then(() => {
                    this.isLoadingStatus = false;
                    this.job.status = this.newStatus;
                });
            })
        },
        showDeleteLineItem(lineItem) {
            this.isShowingDeleteLineItem = true;
            this.deleteLineItem = lineItem;
        },
        cancelDeleteLineItem() {
            this.deleteLineItem = {};
            this.isShowingDeleteLineItem = false;
        },
        confirmDeleteLineItem() {
            console.log('deleting lineitem')
            this.$courier.JobLineItems.removeById(this.deleteLineItem.id).then(() => {
                this.cancelDeleteLineItem();
                this.getLineItems();
            });
        },
        editLineItem(lineItem) {
            this.currentLineItem = JSON.parse(JSON.stringify(lineItem));
            this.isEditingLineItem = true;
        },
        showDeleteNote(note) {
            this.deleteNote = note;
            this.isShowingDelete = true;
        },
        cancelDeleteNote() {
            this.deleteNote = {};
            this.isShowingDelete = false;
        },
        confirmDeleteNote() {
            this.$courier.JobNotes.removeById(this.deleteNote.id).then(() => {
                this.cancelDeleteNote();
                this.getNotes();
            });
        },
        editNote(note) {
            this.currentNote = JSON.parse(JSON.stringify(note));
            this.isEditingNote = true;
        },
        changeNote() {
            if (this.isEditingNote) {
                this.$courier.JobNotes.updateById(this.currentNote.id, this.currentNote).then(() => {
                    this.currentNote = {};
                    this.isEditingNote = false;
                    this.getNotes();
                });
            } else {
                this.currentNote.parentJobId = this.job.id;
                this.$courier.JobNotes.add(this.currentNote).then(() => {
                    this.currentNote = {};
                    this.getNotes();
                });
            }
        },
        getNotes() {
            this.$courier.Jobs.withId(this.job.id).Notes.getData(1, 250, '').then(response => {
                if (response && response.payload) {
                    this.job.notes = response.payload.sort((a, b) => {
                        return new Date(b.dateAdded) - new Date(a.dateAdded);
                    });
                }
            });
        },
        getLineItems() {
            this.$courier.Jobs.withId(this.job.id).LineItems.getData(1, 250, '').then(response => {
                if (response && response.payload) {
                    this.job.lineItems = response.payload.sort((a, b) => {
                        return new Date(b.dateAdded) - new Date(a.dateAdded);
                    });
                }
            })
        },
        addLineItem() {
            if (this.isEditingLineItem) {
                this.$courier.JobLineItems.updateById(this.currentLineItem.id, this.currentLineItem).then(() => {
                    this.currentLineItem = {};
                    this.isEditingLineItem = false;
                    this.getLineItems();
                    this.updatePrice();
                });
            } else {
                this.$courier.Jobs.withId(this.job.id).LineItems.add(this.currentLineItem).then(() => {
                    this.getLineItems();
                    this.currentLineItem = {};
                    this.updatePrice();
                });
            }
        },
        async updatePrice() {
            this.$courier.Jobs.getById(this.job.id).then(job => {
                job.price = this.totalCost;

                this.$courier.Jobs.updateById(this.job.id, job).then(() => {
                    this.job.price = job.price;
                });
            })
        },
        async getJob() {
            this.job = await this.$courier.Jobs.getById(this.$route.params.jobId);

            this.newStatus = this.job.status;
            if (this.job.contactId) {
                this.$courier.Contacts.getById(this.job.contactId).then(contact => {
                    this.job.contact = contact;
                });
            }

            if (this.job.coffeeCupUserId) {
                this.$courier.SystemUsers.getData(1, 100, '').then(resp => {
                    console.log(resp)
                })


                this.$courier.SystemUsers.getById(this.job.coffeeCupUserId).then(contact => {
                    this.job.coffeeCupContact = contact;
                }).catch(ex => {
                    console.log(ex);
                });
            }

            if (this.job.parentCompanyId) {
                this.$courier.Companies.getById(this.job.parentCompanyId).then(comp => {
                    this.job.company = comp;
                });
            }

            this.getNotes();

            this.getLineItems();

            this.getXeroLinks();
        },
        getXeroLinks() {
            this.$courier.Companies.withId(this.job.parentCompanyId).XeroLinks.getData(1, 500, '').then(links => {
                if (links && links.payload && links.payload.length > 0) {
                    this.companyXeroLinks = links.payload;
                }
            })
        },
        async sendInvoiceToXero() {
            this.isLoadingXeroInvoice = true;

            //remove the convenience props from invoice line items

            var generatedInvoice = await this.$courier.XeroInvoiceExternal.add(
                this.currentInvoice,
                false,
                [{ key: "tenantId", value: this.currentInvoice.tenantId }]
            );

            //update job as invoice sent

            this.setInvoiceSent();

            this.isLoadingXeroInvoice = false;
            this.hasSuccesfullySentInvoice = true;
            this.invoiceMessage = "Successfully sent invoice to xero";

            //add it into the log

            //generate the add object

            var invoiceLogItem = {
                reference: this.currentInvoice.reference,
                date: this.currentInvoice.date,
                dueDate: this.currentInvoice.dueDate,
                xeroTenantId: this.currentInvoice.tenantId,
                xeroContactId: this.currentInvoice.contactId,
            };
            this.$courier.Companies.withId(this.job.parentCompanyId)
                .Invoices.add(invoiceLogItem)
                .then((newInvoice) => {
                    //generate the line items

                    for (var lineItem of this.currentInvoice.lineItems) {
                        var newLineItem = {
                            accountCode: lineItem.accountCode,
                            accountCodeName: lineItem.accountCodeName,
                            description: lineItem.description,
                            quantity: lineItem.quantity,
                            unitCost: lineItem.unitCost,
                        };

                        this.$courier.Invoices.withId(newInvoice.id)
                            .LineItems.add(newLineItem)
                            .then((newLineItem) => {
                                //idk what to do here with the ne wline item
                            });
                    }
                });
        },
        async showSendToXero(xeroLink) {
            this.hasSuccesfullySentInvoice = false;
            this.invoiceMessage = '';

            this.isLoadingSendToXero = true;

            var company = await this.$courier.Companies.getById(this.job.parentCompanyId);

            var accCode = await this.$courier.XeroAccountExternal.getById(
                this.defaultAccountCode,
                [
                    {
                        key: "tenantId",
                        value: xeroLink.xeroTenantId,
                    },
                ]
            );


            var invoice = {};

            invoice.date = new Date();
            var date = new Date();
            date.setDate(date.getDate() + company.invoicingPeriod);

            invoice.dueDate = date;
            invoice.reference = this.job.poNumber;
            invoice.lineItems = [];

            this.job.lineItems.forEach(lineItem => {
                invoice.lineItems.push({
                    description: lineItem.description,
                    quantity: lineItem.quantity,
                    unitCost: lineItem.price,
                    accountCode: accCode.code,
                    accountCodeId: accCode.id,
                    accountCodeName: accCode.name,
                    taxRate: 20
                });
            });

            this.currentInvoice = invoice;
            this.isLoadingSendToXero = false;
            this.isShowingSendToXero = true;
        }
    },
    data() {
        return {
            jobToDelete: {},
            currentInvoice: {},
            isLoadingXeroInvoice: false,
            hasSuccesfullySentInvoice: false,
            invoiceMessage: '',
            isShowingSendToXero: false,
            isLoadingSendToXero: false,
            isDoneSendingToXero: false,
            job: {},
            companyXeroLinks: [],
            defaultAccountCode: 'dd74964a-53b7-42f5-99ee-73f08541bd3f',
            newStatus: '',
            isLoadingInvoiceSent: false,
            isLoading: true,
            isEditingNote: false,
            currentNote: {
                note: ''
            },
            isShowingDelete: false,
            deleteNote: {},
            tab: '',
            isEditingLineItem: false,
            currentLineItem: {},
            isShowingDeleteLineItem: false,
            deleteLineItem: {},
            isLoadingStatus: false,
            isShowingDeleteJob: false
        }
    },
}
</script>
 
<style>

</style>